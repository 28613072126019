





















import { Component, Prop, Vue } from "vue-property-decorator";
import { BModal, BImg, BSkeletonImg } from "bootstrap-vue";

import ICMButton from "./ICMButton.vue";

@Component({ components: { BModal, BImg, BSkeletonImg, "icm-button": ICMButton } })
export default class ICMImageVisualizer extends Vue {
	@Prop({ default: "image-visualizer" }) id: string;
	@Prop({ default: "" }) title: string;
	@Prop({ default: "" }) imageUrl: string;
	@Prop({ default: "" }) alt: string;
	@Prop({ default: false }) hideHeader: boolean;

	isLoadingImage = true;
}
