











































import { Component, Vue } from "vue-property-decorator";
import SwiperClass, { SwiperOptions } from "swiper";
import { BButtonGroup, BImg } from "bootstrap-vue";

import PostsCarousel from "@/layouts/components/blog/user/PostsCarousel.vue";
import ICMButton from "@/layouts/components/base/ICMButton.vue";
import ICMArrowButton from "@/layouts/components/base/ICMArrowButton.vue";
import ICMSlider from "@/layouts/components/base/ICMSlider.vue";
import ICMImageVisualizer from "@/layouts/components/base/ICMImageVisualizer.vue";

import { BlogPost, IdealMktCategories } from "@/interfaces/blog_post";

import BlogPostAppModel from "@/api/blog_post.app.model";
import MonthlyCalendarAppModel from "@/api/monthly_calendar.app";
import { MonthlyCalendar } from "@/interfaces/monthly_calendar";
import { inflateEntity } from "@/api/_request";

@Component({
	components: {
		PostsCarousel,
		BButtonGroup,
		BImg,
		"icm-arrow-button": ICMArrowButton,
		"icm-button": ICMButton,
		"icm-slider": ICMSlider,
		"icm-image-visualizer": ICMImageVisualizer,
	},
})
export default class BlogPostsCommemorativeDate extends Vue {
	blogPostModel = BlogPostAppModel;
	monthlyCalendarModel = MonthlyCalendarAppModel;

	posts: BlogPost[] = [];

	monthlyCalendar: MonthlyCalendar[] = [];

	currentMonth = 0;

	swiper: SwiperClass = {} as SwiperClass;
	swiperOptions: SwiperOptions = {
		spaceBetween: 0,
		allowTouchMove: false,
	};

	isSliding = true;

	get commemorativePosts() {
		return this.posts.filter(post => post.eventDate && this.getMonth(post.eventDate) === this.currentMonth);
	}

	get formattedMonths() {
		return this.monthlyCalendar.map(calendar => ({
			...calendar,
			month: this.getLongMonth(calendar.month),
		}));
	}

	get currentMonthCalendar() {
		return this.monthlyCalendar[this.swiper.realIndex];
	}

	async mounted() {
		this.loadData();
	}

	async loadData() {
		this.$store.dispatch("app/showLoading");

		try {
			const posts = (await this.blogPostModel.search({
				join: { field: "mainCategory" },
				filter: {
					field: "mainCategory.name",
					operator: "$eq",
					value: IdealMktCategories.COMMEMORATIVE_DATE,
				},
				sort: {
					field: "eventDate",
					order: "ASC",
				},
			})) as BlogPost[];

			this.posts = posts.map(post => inflateEntity(post) as BlogPost);

			this.monthlyCalendar = await this.monthlyCalendarModel.getMonthlyCalendars();
			this.setCurrentMonth(this.swiper);
			this.isSliding = false;
		} catch (error) {
			console.log(error);
		}

		this.$store.dispatch("app/hideLoading");
	}

	setCurrentMonth(swiper: SwiperClass) {
		this.swiper = swiper;

		if (swiper && this.monthlyCalendar.length) {
			this.currentMonth = this.monthlyCalendar[swiper.realIndex].month;
		}
	}

	getLongMonth(month: number): string {
		const monthName = new Date(2022, month - 1).toLocaleDateString("pt-BR", { month: "long" });
		return monthName.charAt(0).toUpperCase() + monthName.slice(1);
	}

	getMonth(date: string | Date): number {
		return new Date(date).getMonth() + 1;
	}
}
