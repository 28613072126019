import { MonthlyCalendar } from "@/interfaces/monthly_calendar";
import { CrudModel } from "./_crud";

class MonthlyCalendarAppModel extends CrudModel {
	constructor() {
		super("app/monthly_calendar");
	}

	async getMonthlyCalendars(): Promise<MonthlyCalendar[]> {
		return this.get("getMonthlyCalendars");
	}
}

export default new MonthlyCalendarAppModel();
