












import { Component, Prop, Vue } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";

@Component({ components: { BButton } })
export default class ICMButton extends Vue {
	@Prop({ default: "primary" }) variant: "primary" | "outline-primary";
	@Prop({ default: "" }) href: string;
}
